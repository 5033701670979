import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import Template from '../layouts/Template';

const NotFoundPage = () => (
	<Template title="404 page" desc="sorry you found a broken link!">
		<StyledBanner>
			<div className="container">
				<div>
					<h1 className="">404 - Page not found.</h1>
					<p className="h2">Well that sucks. </p>
					<iframe
						src="https://giphy.com/embed/snwvCcEKk33Hy"
						width="480"
						height="360"
						frameBorder="0"
						className="giphy-embed"
						allowFullScreen
						title="Sorry"
					/>
					<p>
						Report a bug here: <Link to="/contact">Contact</Link>
					</p>
				</div>
			</div>
		</StyledBanner>
	</Template>
);

export default NotFoundPage;

const StyledBanner = styled.section`
	.hero {
		${tw`flex flex-wrap`};
		&__title {
			${tw`leading-tight tracking-medium items-center my-3 text-48 lg:text-64 lg:w-2/3`};
			b {
				${tw`font-semibold`};
			}
		}
		&__image {
			${tw`flex justify-center items-center lg:w-1/3`};
		}
	}
	.giphy-embed {
		max-width: 100%;
	}
`;
